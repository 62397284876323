import React, { Component } from 'react';

class MainBlock extends Component {

    render() {
        return <>
<h2 id="experience"># Experience</h2>
<p><a href="/">Check out my projects</a></p>
<h3>## Leipzig Heart Institute GmbH</h3>
<div className="block">
    <p className="dates">07.2021 - present, Leipzig, Germany</p>
    <p className="position">Software Developer</p>
</div>

<h3>## Hochschule für Technik, Wirtschaft und Kultur Leipzig</h3>
<div className="block">
    <p className="dates">02.2020 - 06.2021, Leipzig, Germany</p>
    <p className="position">Research Assistant</p>
    <h3>## Hochschule für Telekommunikation Leipzig</h3>
    <p className="dates">12.2017 - 01.2020, Leipzig, Germany</p>
    <p className="position">Research Assistant &lt;- Intern</p>
</div>
<div className="block">
    <p>Deploying and evaluating open source LTE, working with LTE
        and 5G NR 3GPP specifications for mobile network performance evaluation for research projects. </p>
    <p>Developing an API specification using OpenAPI specification and corresponding REST backend (Go) and clients
        (Python, Go, ReactJS).</p>
    <p>Developing a web graphical interface for data analytics and real-time monitoring (ReactJS).</p>
    <p>Co-/Supervising students' projects and thesis</p>
    <p>Presenting and delivering results at internatinal conferences and european projects.</p>
</div>
<h3>## Siberian State University for Telecommunications and Information Sciences</h3>
<div className="block">
    <p className="dates">6.2012 - 8.2016, Novosibirsk, Russia</p>
    <p className="position">Team leader &lt;- Programmer</p>
    <p>Developing university web services, providing a technical support for university IT systems.</p>
    <p>As a team leader, supervised a team of developers and IT specialists
        for deploying and integrating university IT systems in accordance with the federal law.</p>
</div>

<h2 id="skills"># Skills</h2>
<div className="block">
    <h3>## Full-stack development</h3>
    <ul>
        <li>JavaScript (ReactJS/Redux, NodeJS), CSS, SASS</li>
        <li>Python (Flask)</li>
        <li>Go</li>
        <li>OpenAPI, GraphQL</li>
        <li>MongoDB</li>
        <li>Development and deployment with Docker</li>
        <li>Deployment with Nginx, pm2</li>
        <li>C++, Kotlin (Android), C# (Unity), Swift (iOS)</li>
    </ul>

    <h3>## Wireless communications</h3>
    <ul>
        <li>LTE RAN protocol stack development and evaluation</li>
        <li>Open Air Interface, srsLTE - deployment and extension</li>
        <li>Live network measurements for LTE</li>
    </ul>

    <h3>## Data analytics</h3>
    <ul>
        <li>LTE RAN evaluation</li>
        <li>Python - Jupyter, Numpy, Pandas, Keras, TensorFlow, multiprocessing</li>
        <li>Perl - text data parsing</li>
        <li>Apache Arrow, Apache Spark</li>
        <li>Parsing and evalution of big data files</li>
    </ul>
</div>

<h2 id="education"># Education</h2>
<div className="block">
    <h3>## Hochschule für Telekommunikation Leipzig</h3>
    <p className="dates">2016 - 2018, Leipzig, Germany</p>
    <p className="position">Master of Engineering</p>

    <h3>## Siberian State University for Telecommunications and Information Sciences</h3>
    <p className="dates">2011 - 2015, Novosibirsk, Russia</p>
    <p className="position">Bachelor of Engineering</p>
</div>

<h2 id="references"># References</h2>
<div className="block">
    <h3>## Papers</h3>
    <ul>
        <li>M. Einhaus, <strong>I. Kim</strong>, M. B. Charaf and P. Arnold,
            "Processing Time Aware Resource Allocation in Software Defined RANs",
            2019 IEEE Conference on Standards for Communications and Networking (CSCN), 
            Granada, Spain, 2019 </li>
        <li>M. Einhaus, <strong>I. Kim</strong>, M. B. Charaf and J. Klinger,
            "A Framework for RAN Performance Evaluations based on Software Defined Radio",
            Mobile Communication - Technologies and Applications; 24.
            Symposium, Osnabrück, Germany, 2019 </li>
        <li>M. Einhaus, M. B. Charaf, <strong>I. Kim</strong> and P. Arnold,
            "Bandwidth Part Adaptation and Processing Time Evaluation with OpenAirInterface",
            2018 IEEE 88th Vehicular Technology Conference (VTC-Fall),
            Chicago, IL, USA, 2018</li>
    </ul>

    <h3>## Patent applications</h3>
    <ul>
        <li>M.Einhaus, P. Arnold, G. Zimmermann, <strong>I. Kim</strong>, M. B. Charaf,
            Patent Application,
            "Techniques for controlling computational resources of a radio access network",
            June 2018 </li>
    </ul>

    <h3>## Project contributions</h3>
    <ul>
        <li>5G-MoNArch Deliverable D4.2
            "Final design and evaluation of resource elastic functions",
            March 2019</li>
        <li>Video demonstration for Mobile World Congress 2019,
            Mach 2019</li>
        <li>5G-MoNArch Deliverable D4.1
            "Architecture and mechanismforresource elasticity provisioning“,
            May 2018</li>
        <li>Video demonstration for EU Y1 report of 5G-MoNArch, 2018</li>
    </ul>
</div>
        </>
    }
}

export default MainBlock;